import { convertFileToArrayBuffer2 } from '../Screens/FileUpload/constant'
import { BlockBlobClient } from '@azure/storage-blob'
import { API_UPLOAD_TO_SHAREPOINT } from '../urls'
import { getUploadHeaders } from './headerconstants'
const API_URL = import.meta.env.VITE_KDD_API

const handleUpload = (file: any, sas_url: string) => {
  const selectedFile = file
  const sasTokenUrl = sas_url
  if (!sas_url) {
    console.error('SAS url not defined for file', file)
    return Promise.reject('Invalid SAS URL')
  }
  return new Promise((resolve, reject) => {
    convertFileToArrayBuffer2({ id: sas_url, file: selectedFile })
      .then((fileArrayBuffer) => {
        if (!fileArrayBuffer) return null
        const blockBlobClient = new BlockBlobClient(sasTokenUrl)
        return blockBlobClient
          .uploadData(fileArrayBuffer.blob, {
            blockSize: 1024 * 1024 * 50,
            concurrency: 10,
          })
          .then()
      })
      .then(() => {
        resolve('uploaded')
      })
      .catch((error: unknown) => {
        console.log('Error', error)
      })
  })
}

const checkForMultipleFileValidation = (uploadAllFileList: string | any[]) => {
  let icsFiles = 0
  let mpFiles = 0
  for (let i = 0; i < uploadAllFileList.length; i++) {
    if (uploadAllFileList[i]?.name.split('.').pop() === 'ics') icsFiles++
    if (
      uploadAllFileList[i]?.name.split('.').pop() === 'vtt' ||
      uploadAllFileList[i]?.name.split('.').pop() === 'txt' ||
      uploadAllFileList[i]?.name.split('.').pop() === 'm4a' ||
      uploadAllFileList[i]?.name.split('.').pop() === 'mp4'
    )
      mpFiles++
  }
  if ((icsFiles === 1 && mpFiles > 0) || (icsFiles === 0 && mpFiles === 0)) return true
  else return false
}

const checkMinimumFileSizeValidation = (fileObj: any) => {
  if (
    (fileObj.name.split('.').pop() === 'docx' && fileObj.size < 12.5 * 1024) ||
    ((fileObj.name.split('.').pop() === 'ppt' || fileObj.name.split('.').pop() === 'pptx') &&
      fileObj.size < 32.2 * 1024)
  )
    return false
  else return true
}

const checkForAllFilesSizeValidation = (uploadAllFileList: string | any[]) => {
  for (let i = 0; i < uploadAllFileList.length; i++) {
    if (!checkForMaximumFileSize(uploadAllFileList[i])) return false
  }
  return true
}

const checkForMaximumFileSize = (fileObj: any) => {
  if (fileObj.size > 2 * 1024 * 1024 * 1024) return false
  else return true
}

const checkForFileTypes = (fileObj: any) => {
  const fileTypesAllowed = [
    'vtt',
    'txt',
    'ics',
    'mp4',
    'm4a',
    'xls',
    'ppt',
    'msg',
    'eml',
    'png',
    'jpeg',
    'docx',
    'pdf',
    'pptx',
    'xlsx',
  ]
  if (!fileTypesAllowed.includes(fileObj?.name.toString().split('.').pop())) return false
  else return true
}

const checkForMultipleDuplicates = (uploadAllFileList: any) => {
  const seen = new Set()
  for (const file of uploadAllFileList) {
    if (seen.has(file.name)) {
      return true
    }
    seen.add(file.name)
  }
  return false
}

const checkFileNameExists = (filobj: any, uploadAllFileList: any) => {
  for (let i = 0; i < uploadAllFileList.length; i++) {
    if (uploadAllFileList[i]?.name === filobj?.name) return true
  }
  return false
}

const uploadFilesToSasUrls = async (
  result: any,
  uploadAllFilesObjectList: any,
  existingFilesData: any,
) => {
  if (uploadAllFilesObjectList.length > 0) {
    for (let i = 0; i < uploadAllFilesObjectList.length; i++) {
      await handleUpload(
        uploadAllFilesObjectList[i],
        result?.sas_urls?.[`${uploadAllFilesObjectList[i].name}`],
      )
    }
  }
  if (existingFilesData.length !== 0) {
    await handleUpload(existingFilesData, result?.sas_urls?.[`${existingFilesData.name}`])
  }
}

const readToSharepointApi = async (
  idToken: string,
  accessToken: string,
  projectId: string,
  data: any,
) => {
  const response = await fetch(`${API_URL}/${API_UPLOAD_TO_SHAREPOINT}`, {
    method: 'POST',
    headers: getUploadHeaders(accessToken, idToken, projectId),
    body: JSON.stringify(data),
  })
  if (!response?.ok) {
    const message = `An error has occurred: ${response.statusText}`
    throw new Error(message)
  }
}

const uploadTextApi = async (
  idToken: string,
  accessToken: string,
  projectId: string,
  data: any,
) => {
  const response = await fetch(`${API_URL}/${API_UPLOAD_TO_SHAREPOINT}`, {
    method: 'POST',
    headers: getUploadHeaders(accessToken, idToken, projectId),
    body: JSON.stringify(data),
  })
  if (!response?.ok) {
    const message = `An error has occurred: ${response.statusText}`
    throw new Error(message)
  }
}

const uploadTableApi = async (
  idToken: string,
  accessToken: string,
  projectId: string,
  data: any,
) => {
  const response = await fetch(`${API_URL}/${API_UPLOAD_TO_SHAREPOINT}`, {
    method: 'POST',
    headers: getUploadHeaders(accessToken, idToken, projectId),
    body: JSON.stringify(data),
  })
  if (!response?.ok) {
    const message = `An error has occurred: ${response.statusText}`
    throw new Error(message)
  }
}

const checkForFileValidation = (fileName: string, uploadAllFileList: any) => {
  let icsFiles = 0
  let mpFiles = 0
  for (let i = 0; i < uploadAllFileList.length; i++) {
    if (uploadAllFileList[i]?.name.split('.').pop() === 'ics') icsFiles++
    if (
      uploadAllFileList[i]?.name.split('.').pop() === 'vtt' ||
      uploadAllFileList[i]?.name.split('.').pop() === 'txt' ||
      uploadAllFileList[i]?.name.split('.').pop() === 'm4a' ||
      uploadAllFileList[i]?.name.split('.').pop() === 'mp4'
    )
      mpFiles++
  }
  if (
    fileName.split('.').pop() === 'vtt' ||
    fileName.split('.').pop() === 'txt' ||
    fileName.split('.').pop() === 'm4a' ||
    fileName.split('.').pop() === 'mp4'
  )
    mpFiles++
  if (fileName.split('.').pop() === 'ics') icsFiles++
  if (icsFiles < 2 && (mpFiles > 0 || mpFiles === 0)) return true
  else return false
}

const checkForMultipleIcsFile = (fileName: string, uploadAllFileList: any) => {
  let icsFiles = 0
  for (let i = 0; i < uploadAllFileList.length; i++) {
    if (uploadAllFileList[i]?.name.split('.').pop() === 'ics') icsFiles++
  }
  if (fileName.split('.').pop() === 'ics') icsFiles++
  if (icsFiles > 1) return true
  else return false
}

const checkForDuplicates = (fileName: string, uploadAllFileList: any) => {
  for (const file of uploadAllFileList) {
    if (file.name === fileName) {
      return true
    }
  }
  return false
}
const goToOutputs = (projectId: string) => {
  const commonPageUrl = new URL(import.meta.env.VITE_ASCEND_1_1_URL)
  commonPageUrl.searchParams.append('ascend_project_id', projectId)
  window.location.href = commonPageUrl.toString()
}

const isValidFileExtension = (filename: string, accept: string) => {
  // Extract valid extensions from the accept string
  const validExtensions = accept.split(',').map((ext: string) => ext.trim().toLowerCase())

  // Split the filename by '.' to get all parts
  const parts = filename.split('.')

  // If there's only one part, it means there's no extension
  if (parts.length < 2) return false

  // Extract the last part as the extension
  const extension = `.${parts.pop()!.toLowerCase()}`

  // Check if the extension is valid
  if (!validExtensions.includes(extension)) return false

  // Ensure there are no additional extensions (other dots should only be part of the base name)
  return parts.length === 1 // Only the base name should remain after popping the last parts
}
export {
  handleUpload,
  checkForMultipleFileValidation,
  checkForMultipleDuplicates,
  readToSharepointApi,
  uploadFilesToSasUrls,
  uploadTableApi,
  uploadTextApi,
  checkForFileValidation,
  checkForDuplicates,
  goToOutputs,
  checkForFileTypes,
  checkForMaximumFileSize,
  checkMinimumFileSizeValidation,
  checkForAllFilesSizeValidation,
  checkForMultipleIcsFile,
  checkFileNameExists,
  isValidFileExtension,
}
