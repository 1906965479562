export type BlobWithFilenameAndId = {
  blob: Blob
  filename: string
  id: string
}

export interface IAppFile {
  id: string
  file: File
}

export const maximumFileSizeWarning =
  'The file size exceeds the maximum upload capability of 2GB, please upload a smaller file to proceed'
export const minimumFileSizeWarning =
  'The file size is suspiciously small, please check that it is not empty'

export const convertFileToArrayBuffer2 = (appFile: IAppFile): Promise<BlobWithFilenameAndId> => {
  const { file } = appFile
  // Follow the same recursive splitting as that of convertFileToArrayBuffer
  return new Promise((resolve, reject) => {
    if (!file || !file.name) {
      reject(new Error('Invalid or missing file.'))
    }
    const parts: (string | ArrayBuffer | null)[] = []
    const fileSize = file.size
    const sliceSize = 1024 * 1024 * 25
    const count = Math.ceil(fileSize / sliceSize)
    let index = 0

    const sliceM = () => {
      console.log('AppConvert', sliceSize)
      const start = index * sliceSize
      console.log('start', typeof file)
      const end = Math.min(start + sliceSize, fileSize)
      // console.log('end', file.slice(start, end))
      // try {
      //   file.slice(start, end)
      // } catch (err) {
      //   console.log('error', err)
      // }
      const slice = file.slice(start, end)
      // console.log('Entered', slice)
      const fr = new FileReader()
      fr.onload = function (event) {
        console.log('Onload', event)
        parts.push(this.result)
        index++
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        if (index < count) sliceM()
        else done(parts) // we're done, use some callback
      }
      fr.onerror = function () {
        console.log('Err')
      }
      console.log('Entered2')
      fr.readAsArrayBuffer(slice)
      console.log('EnteredBelow')
    }

    sliceM()

    const done = (parts: any) => {
      console.log('done', parts)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const blob = new Blob(parts)
      const v = {
        id: appFile.id,
        filename: appFile.file.name,
        blob,
      } as BlobWithFilenameAndId

      resolve(v)
    }
  })
}
