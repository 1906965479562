/* eslint-disable react/jsx-key */
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Modal, Input, Typography, Select, Button, Tooltip, Form, Spin } from 'antd'

import './index.scss'
import {
  addSnippetTooltipdisabled,
  addSnippetTooltipenabled,
  description,
  modalHeading,
  validationStatment,
} from './constants'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { DataContext } from '../../DataContext'
import AddImagesComponent from '../AddImageComponent'
import getToken from '../../addTokenInterceptor'

const { Option } = Select

type ISnippetProps = {
  parentList: { label: string; value: number }[]
  text?: string
  roleName?: string
  disabled?: boolean
  showModal: (index: number) => void
  currentActiveIndex: number
  handleModalOk: () => void
  setParentList: Dispatch<SetStateAction<{ label: string; value: number }[]>>
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  setInputValue: Dispatch<SetStateAction<{ name: string; parentId: number }>>
  setCurrent: Dispatch<SetStateAction<{ value: number }>>
  inputValue: { name: string; parentId: number }
  showModalLoader: boolean
  isModalOpen: boolean
  selectKey: number
  defaultLabel: string
  error: string
}

const SnippetModal = (props: ISnippetProps) => {
  const [selectedParent, setSelectedParent] = useState({ name: '', parentId: 0 })

  useEffect(() => {
    if (props.inputValue.name === '' && props.parentList.length > 0)
      setSelectedParent({ name: props.parentList[0].label, parentId: props.parentList[0].value })
    else setSelectedParent(props.inputValue)
  }, [props.parentList, props.inputValue])
  const {
    setChatActiveIndex,
    setUploadImageList,
    setUploadedFileName,
    setUploadImagePosition,
    setPtg,
    setAddImageClicked,
    setAddImageIndex,
    addImageClicked,
    setIsUploaded,
    loading,
    setLoading,
    chatActiveIndex,
  } = useContext(DataContext)

  const [form] = Form.useForm()
  const mergedArrow = {
    pointAtCenter: true,
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  const handleCancel = () => {
    // props.setParentList([{ label: 'Create new root', value: 0 }])
    setLoading(false)
    setChatActiveIndex(-1)
    props.setIsModalOpen(false) // close the modal
    setUploadedFileName('')
    setIsUploaded(false)
    setUploadImagePosition('')
    setUploadImageList([])
    setAddImageClicked(false)
    setAddImageIndex(-1)
    setPtg(0)
  }

  const handleChange = (value: string | number, type: string) => {
    props.setInputValue({ ...props.inputValue, [type]: value })
  }

  function validateForm() {
    if (props.inputValue.name !== '') {
      return true
    }
    return false
  }

  return (
    <>
      <Tooltip
        placement='top'
        title={props?.disabled ? addSnippetTooltipdisabled : addSnippetTooltipenabled}
        arrow={mergedArrow}
      >
        <button
          className='square-button'
          style={
            props?.disabled
              ? { opacity: '60%', background: '#F4F4F499', cursor: 'not-allowed' }
              : { opacity: '1', background: '#F4F4F4', cursor: 'pointer' }
          }
          onClick={async () => {
            props.setCurrent({ value: props.currentActiveIndex })
            await setChatActiveIndex(props.currentActiveIndex)
            console.log('currentIndex', props.currentActiveIndex)
            props.showModal(props.currentActiveIndex)
          }}
        >
          {props.showModalLoader ? <Spin indicator={antIcon} className='loader-spin' /> : <>+</>}
        </button>
      </Tooltip>
      {props.currentActiveIndex === chatActiveIndex && (
        <Modal
          className='modal_snippet'
          width={600}
          visible={props.isModalOpen}
          onOk={() => {
            props.handleModalOk()
          }}
          confirmLoading={false}
          onCancel={handleCancel}
          footer={[
            <Button key='cancel' onClick={handleCancel} disabled={loading}>
              Cancel
            </Button>,
            <Tooltip
              key={'tooltip'}
              placement='top'
              title={!validateForm() ? validationStatment : ''}
            >
              <Button
                key={'done-key'}
                className={
                  validateForm() && !addImageClicked ? 'done-button' : 'disabled-done-button'
                }
                type='primary'
                onClick={() => {
                  props.handleModalOk()
                }}
                disabled={!validateForm() || addImageClicked}
                loading={loading}
              >
                {/* {props.showModalLoader && <Spin indicator={antIcon} className='loader-spin' />} */}
                Done
              </Button>
            </Tooltip>,
          ]}
          okText='Done'
        >
          <h2>{modalHeading}</h2>
          <div
            className='bottomLine'
            style={{ borderBottom: '1px solid #E8E8E8', margin: '10px 0' }}
          />
          <p className='modal_description'>{description}</p>
          <Typography.Title level={5} className='input_label'>
            Snippet Name
          </Typography.Title>
          <Form form={form}>
            <Input
              placeholder='Enter Snippet Name'
              value={props.inputValue.name}
              onChange={(e) => handleChange(e.target.value, 'name')}
              defaultValue=''
            />
            {props.error ? <span className='error'>{props.error}</span> : null}
          </Form>
          <Typography.Title level={5} className='input_label'>
            Parent
          </Typography.Title>
          <Select
            key={props.selectKey}
            style={{ width: '100%' }}
            placeholder='Select a parent'
            onChange={(e) => handleChange(e, 'parentId')}
            disabled={props.parentList.length === 0}
            // defaultValue={props.parentList.length > 0 ? props.parentList[0].value : ''}
            defaultValue={props.defaultLabel}
          >
            {props.parentList.map((list: { label: string; value: number }, index: number) => (
              <Option key={index} value={list.value}>
                {list.label}
              </Option>
            ))}
          </Select>
          <AddImagesComponent currentActiveIndex={props.currentActiveIndex} />
          <div
            className='bottomLine'
            style={{
              borderBottom: '1px solid #E8E8E8',
              marginTop: '25px',
              marginRight: '-24px',
              marginLeft: '-24px',
            }}
          />
          {/* <Spin spinning={spinning} percent={percent} fullscreen /> */}
        </Modal>
      )}
    </>
  )
}
export default SnippetModal
