import React, { useContext, useEffect, useState } from 'react'
import {
  Input,
  Checkbox,
  CheckboxProps,
  Tooltip,
  Progress,
  Upload,
  UploadProps,
  notification,
  NotificationArgsProps,
} from 'antd'
import './index.scss'
import TextArea from 'antd/es/input/TextArea'
import illustrateIcon from '../../assets/Illustration.svg'
import icon2 from '../../assets/icon2.svg'
import { DataContext } from '../../DataContext'
import getToken from '../../addTokenInterceptor'
import Footer from '../../Components/Footer'
import info from '../../assets/Description.svg'
import download from '../../assets/downloadIcon.svg'
import BreadCrumb from '../../Components/BreadCrumb'
import AddSections from '../../Components/AddSectionsComponent'
import HeaderTileWorkshop from '../../Components/HeaderTileWorkshop'
import { handlePreviewClick, handleTemplateDownload } from '../../services/workshopDetailsService'
import { getProjectNames } from '../../services/apiService'
import divider from '../../assets/divider.svg'
import {
  checkFileNameExists,
  checkForFileValidation,
  checkForMaximumFileSize,
  checkMinimumFileSizeValidation,
} from '../../services/fileUploadService'
import { WarningOutlined } from '@ant-design/icons'
import cross from '../../assets/cross.svg'
import check from '../../assets/checkIcon.svg'

import { maximumFileSizeWarning, minimumFileSizeWarning } from './constants'

const WorkshopDetails: React.FC = () => {
  const [projectNames, setProjectNames] = useState('')
  const [workshopName, setWorkshopName] = useState('')
  const {
    setExistingFilesData,
    promptData,
    workshopNameData,
    additionalPromptData,
    setPromptData,
    setWorkshopNameData,
    setadditionalPromptData,
    outputFileNameData,
    setoutputFileNameData,
    currentScreen,
    setCurrentScreen,
    setUpdateFile,
    updateExistingFileChecked,
    setUpdateExistingFileChecked,
    updateFile,
    sections,
    createNewFileChecked,
    setCreateNewFileChecked,
  } = useContext(DataContext)
  const [outputFileName, setOutputFileName] = useState('')
  const [promptValue, setPromptValue] = useState(
    `Generate a workshop deck for ${
      workshopName ? workshopName : `<Workshop Name>`
    } by selecting/adding relevant sections and utilising provided input files.`,
  )
  const [additionalPrompt, setAdditionalPrompt] = useState('')
  const [roleName, setroleName] = useState('')

  const [uploadKey, setUploadKey] = useState<number>(0)
  const [uploadingActive, setUploadingActive] = useState(false)
  const [notify, contextHolder_2] = notification.useNotification()
  const [currentFilesUploading, setCurrentFilesUploading] = useState<IFileUploadingObj[]>([])
  // const [uploadingActive, setUploadingActive] = useState(false)
  const { accessToken, idToken, projectId, user_email } = getToken()
  const [uploadIntervals, setUploadIntervals] = useState(new Map())
  const [removeFileClickedList, setRemoveFileClickedList] = useState<string>([])

  const [sectionsSelected, setSectionsSelected] = useState<string[]>([])
  const onChangeNew: CheckboxProps['onChange'] = (e) => {
    setUpdateFile([])
    setCreateNewFileChecked(e.target.checked)
    if (e.target.checked) setUpdateExistingFileChecked(false)
  }

  interface IFileUploadObj {
    name: string
    size: string
    active: boolean
    percent: any
  }
  interface IFileUploadingObj {
    name: string
    size: string
    active: boolean
    percent: any
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (workshopNameData) setWorkshopName(workshopNameData)
    if (outputFileNameData) setOutputFileName(outputFileNameData)
    if (additionalPromptData) setAdditionalPrompt(additionalPromptData)
    if (promptData) setPromptValue(promptData)
    if (updateFile.length > 0) setCurrentFilesUploading(updateFile)
    if (updateFile.length > 0) setUpdateExistingFileChecked(true)
    if (updateFile.length == 0) setCreateNewFileChecked(true)
    // const ExFiles = []
    setSectionsSelected(sections)
  }, [])

  const handleCallback = (checkedList: string[]) => {
    if (checkedList.length != 0) {
      setSectionsSelected(checkedList)
    } else {
      setSectionsSelected(sections)
    }
  }

  const onChangeExisting: CheckboxProps['onChange'] = (e) => {
    setUpdateExistingFileChecked(e.target.checked)
    if (e.target.checked) setCreateNewFileChecked(false)
  }
  useEffect(() => {
    if (projectId && user_email) {
      const fetchProjectNames = async () => {
        try {
          const names = await getProjectNames(accessToken, idToken, projectId)
          setProjectNames(names)
        } catch (err) {
          console.error(err)
        }
      }
      fetchProjectNames()
    }
  }, [projectId, user_email])

  const simulateUpload = (file: any) => {
    let progress = 0

    const interval = setInterval(() => {
      progress += 10

      setCurrentFilesUploading((prevFiles) =>
        prevFiles.map((fileObj) => {
          if (fileObj.name === file.name) {
            return { ...fileObj, percent: progress } // Create a new object for the file with updated progress
          }
          return fileObj // Return the other files as-is
        }),
      )

      if (progress >= 100) {
        clearInterval(interval)
        setUploadIntervals((prevIntervals) => {
          const updatedIntervals = new Map(prevIntervals)
          updatedIntervals.delete(file.name)
          return updatedIntervals
        })
        setUpdateFile((prevFiles: any) => [
          ...prevFiles,
          {
            name: file.name,
            size: file.size,
            active: true,
            percent: 100,
          },
        ])
        // setUploadAllFilesObjectList((prevList) => [...prevList, file])
        // setExistingFileList(file)
        setExistingFilesData(file)
        setUploadingActive(false)
      }
    }, 500) // Simulate progress every 500ms
    setUploadIntervals((prevIntervals) => new Map(prevIntervals.set(file.name, interval)))
  }
  type NotificationPlacement = NotificationArgsProps['placement']
  const openNotification = (placement: NotificationPlacement, message: string) => {
    notify.info({
      message: message,
      description: '',
      style: {
        width: 700,
        height: 40,
        backgroundColor: '#000000',
        color: 'white',
      },
      placement,
    })
  }
  const OnFileUpload = async (info: IFileUploadObj) => {
    if (checkFileNameExists(info, updateFile)) {
      openNotification(
        'bottom',
        'Phew! We found another file with the same name. Kindly try again with a different file',
      )
    }
    if (currentFilesUploading.length == 1) {
      openNotification('bottom', 'Please only 1 file is allowed for update to work.')
    }
    if (
      !checkFileNameExists(info, updateFile) &&
      checkForFileValidation(info.name, updateFile) &&
      currentFilesUploading.length < 1
    ) {
      const updatedList = [...currentFilesUploading]
      setUploadingActive(true)
      const index = updatedList.findIndex((file) => file.name === info.name)

      // If the file is already being uploaded, update its progress
      if (index !== -1) {
        updatedList[index].percent = 0
        setCurrentFilesUploading([...updatedList])
      } else {
        // If it's a new file, add it to the currentFilesUploading array
        if (info.size && !checkPushed(info.name)) {
          setCurrentFilesUploading((prevFiles) => [
            ...prevFiles,
            {
              name: info.name,
              size: info.size,
              active: true,
              percent: 0,
            },
          ])

          // checkForMultipleFileValidation(updateFile)
          // checkForMultipleDuplicates(updateFile)

          // Simulate the upload for the new file
          simulateUpload(info)
        }
      }
    }
  }
  const checkPushed = (fileName: string) => {
    for (let i = 0; i < currentFilesUploading.length; i++) {
      if (currentFilesUploading[i]?.name === fileName) return true
    }
  }

  const Uploadprops: UploadProps = {
    action: undefined,
    beforeUpload: (file) => {
      OnFileUpload(file)
      return false
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  }

  const generateWarning = (fileObj: IFileUploadObj) => {
    if (!checkForMaximumFileSize(fileObj)) return maximumFileSizeWarning
    else if (!checkMinimumFileSizeValidation(fileObj)) return minimumFileSizeWarning
    else return ''
  }

  const checkCondition = () => {
    if (
      outputFileName === '' ||
      (promptValue !== '' && promptValue.length < 20) ||
      workshopName === '' ||
      sectionsSelected.length == 0 ||
      uploadingActive == true
    ) {
      return true
    }
    return false
  }

  const navigateToFileUpload = () => {
    setCurrentScreen('fileUpload')
  }

  const onRemoveFile = (fileObj: { name: string }) => {
    setUploadIntervals((prevIntervals) => {
      const interval = prevIntervals.get(fileObj.name)
      if (interval) {
        clearInterval(interval)
      }

      // Remove the file from the map of intervals
      const updatedIntervals = new Map(prevIntervals)
      updatedIntervals.delete(fileObj.name)
      setUploadIntervals(updatedIntervals)

      return updatedIntervals
    })
    setCurrentFilesUploading((prevItems) => {
      const updatedItems = prevItems.filter((item) => item.name !== fileObj.name)
      return updatedItems
    })

    setUpdateFile((prevFiles: any[]) =>
      prevFiles.filter((file: { name: string }) => file.name !== fileObj.name),
    )
    // Add removed file to the list
    setRemoveFileClickedList((prevList: any) => {
      const updatedList = [...prevList, fileObj]
      return updatedList
    })
    // Also remove the file from the upload list
    setUploadingActive(false)

    // Reset the upload key (or another trigger if needed) to ensure new files are accepted after removal
    setUploadKey((prevKey) => prevKey + 1)
  }

  const checkStrokeColor = (fileObj: IFileUploadObj) => {
    let colorCode
    if (!checkForMaximumFileSize(fileObj) && fileObj?.percent === 100) colorCode = '#ff5a43'
    else if (!checkMinimumFileSizeValidation(fileObj) && fileObj?.percent === 100)
      colorCode = '#ed8b00'
    else colorCode = fileObj?.percent === 100 ? '#007CB0' : '#59616C'
    return colorCode
  }
  return (
    <div style={{ height: 'inherit' }}>
      <HeaderTileWorkshop
        projectNames={projectNames}
        roleName={roleName}
        setCurrentScreen={navigateToFileUpload}
      />
      <BreadCrumb setCurrentScreen={setCurrentScreen} currentScreen={currentScreen} />
      {contextHolder_2}
      <div className='workshopDetails-landing'>
        <div className='workshop-details-header'>Workshop Details</div>
        <div className='workshop-details-subheading'>
          Please specify the worksop name, prompts and choose a template for generating the output.
        </div>
        <div className='workshop-details-container'>
          <div className='workshop-name-container'>
            <div className='workshop-name-header'>Workshop Name</div>
            <div className='workshop-name-header-box'>
              <div className='workshop-name-box-header'>Please enter workshop name</div>
              <div className='workshop-name-box'>
                <Input
                  placeholder='Type workshop name'
                  value={workshopName}
                  onChange={(e) => {
                    setWorkshopName(e.target.value)
                    setPromptValue(
                      `Generate a workshop deck for ${
                        e.target.value.length > 0 ? e.target.value : `<Workshop Name>`
                      } by selecting/adding relevant sections and utilising provided input files.`,
                    )
                    setWorkshopNameData(workshopName)
                  }}
                  className='workshop-name-input-field'
                />
              </div>
            </div>
          </div>
          <div className='workshop-prompt-container'>
            <div className='workshop-promt-header'>Enter the prompt for your Workshop</div>
            <div className='workshop-prompt-content'>
              <div className='workshop-prompt-content-left-section'>
                <div className='workshop-prompt-content-top-section'>
                  Enter specific parameters or filters for your Workshop. Use it to limit certain
                  types of information, or set specific criteria for the generated Workshop. Include
                  instructions on what to include or exclude for more precise results.
                </div>
                <div className='prompt-examples'>
                  <div className='prompt-examples-header'>Prompt Examples</div>
                  <ul className='prompt-example-points'>
                    <li className='prompt-examples-pointers'>
                      Create the deck only related to the three-way matching process using the input
                      files.
                    </li>
                    <li className='prompt-examples-pointers'>
                      Create the deck only related to the Journal approval using the input files.
                    </li>
                    <li className='prompt-examples-pointers'>
                      Using the given input files generate the deck regarding the supplier
                      conversion.
                    </li>
                  </ul>
                </div>
              </div>
              <div className='workshop-prompt-content-right-section'>
                <TextArea
                  rows={8}
                  value={promptValue}
                  onChange={(e) => {
                    setPromptValue(e.target.value)
                    setPromptData(e.target.value)
                  }}
                  placeholder='Type your prompt here'
                  maxLength={500}
                  minLength={20}
                />
                <div className='limitation-text'>
                  <span> Enter at least 20 characters</span>
                  <span className='character-count'> {promptValue.length}/500 </span>
                </div>
              </div>
            </div>
          </div>
          <div className='workshop-prompt-container'>
            <div className='workshop-promt-header'>
              Enter the Additional Information Prompt
              <span className='optional-tag'>Optional</span>
            </div>
            <div className='workshop-prompt-content'>
              <div className='workshop-prompt-content-left-section'>
                <div className='workshop-prompt-content-top-section'>
                  Please provide additional context and/or supplementary details in this field to
                  guide the generation process. This helps in refining and directing the output more
                  accurately according to your needs.
                </div>
                <div className='prompt-examples'>
                  <div className='prompt-examples-header'>Prompt Examples</div>
                  <ul className='prompt-example-points'>
                    <li className='prompt-examples-pointers'>
                      I need details of the complete Asset Life cycle covering asset additions,
                      asset adjustments and transfers, asset retirements and reinstatements. Make
                      sure to include different methods of asset additions, what kind of asset
                      adjustments or transfers can be done and retirement types as well. Do not
                      include Asset depreciation details. Using input files, generate a workshop
                      deck which will include all above information
                    </li>
                    <li className='prompt-examples-pointers'>
                      Conversion Entities: Projects and Tasks, Project Budgets and forecasts,
                      Project Invoices and Revenue and Project Costs. I need details of conversion
                      strategies and leading pratices for all above entities. Assumptions: Only
                      active projects to be considered. Generate a workshop deck outlining all above
                      details and use the input files
                    </li>
                    <li className='prompt-examples-pointers'>
                      Generate a workshop deck using input files for Procure to Pay process. I need
                      detail process flows related to requisition creation, purchase ordes creation
                      or generation, receipts, supplier invoices and payments. Include details of
                      accounting entries for above transactional data and underlying subledger
                      accounting configurations driving the the same.
                    </li>
                  </ul>
                </div>
              </div>
              <div className='workshop-prompt-content-right-section'>
                <TextArea
                  rows={10}
                  value={additionalPrompt}
                  onChange={(e) => {
                    setAdditionalPrompt(e.target.value)
                    setadditionalPromptData(additionalPrompt)
                  }}
                  placeholder='Type any information regarding additional files/data provided as an input here'
                  maxLength={5000}
                />
                <div className='limitation-text'>
                  <span className='character-count'> {additionalPrompt?.length}/500 </span>
                </div>
              </div>
            </div>
          </div>
          <div className='workshop-Section-container'>
            <div className='workshop-Section-header'>Workshop Sections</div>
            <div className='workshop-Section-Box'>
              <div className='workshop-Section-box-header'>
                Add certain sections to your workshop so that the AI takes them into account when
                generating the document.
              </div>
              <div className='workshop-add-section-box'>
                {sectionsSelected.length == 0 ? (
                  <>
                    <img src={illustrateIcon} className='illustrate-icon' />
                    <div className='no-sections-added-text'> No sections were added </div>
                  </>
                ) : (
                  <>
                    <img src={icon2} className='illustrate-icon updated-icon' />
                    <div className='no-sections-added-text'>
                      {sectionsSelected.length} sections have been added
                    </div>
                  </>
                )}
                <div className='add-section-button'>
                  <AddSections addSectionCallback={handleCallback} />
                </div>
              </div>
            </div>
          </div>
          <div className='file-update-section-container'>
            <div>
              <div className='file-update-section-header'>
                Do you want to create a new Workshop Decks?
              </div>
              <div className='file-update-section-Box'>
                <div className='workshop-Section-left'>
                  <div className='workshop-update-box-header'>
                    If you choose to create a new file, GenAI will generate a new file. If you
                    choose to update an existing file, you will need to upload your existing file
                    and GenAI will update it.
                  </div>
                  {updateExistingFileChecked && (
                    <div>
                      <div className='required-files-label'>Required Files:</div>
                      <div className='pptx-requirement-label'>
                        <span className='pptx-text'>.pptx</span>
                        <span className='existing-workshop-text'>Existing Workshop Deck File </span>
                        <img src={info} />
                      </div>
                    </div>
                  )}
                </div>
                <div className='selection-box'>
                  <div className='selection-box-header'>Select an option</div>
                  <div className='workshop-add-file-option'>
                    <div className='selection-label'>Create New File</div>
                    <Checkbox
                      className='file-selection-checkbox'
                      checked={createNewFileChecked}
                      onChange={onChangeNew}
                      name='fileValue'
                    />
                  </div>
                  <div className='workshop-add-file-option'>
                    <div className='selection-label'>Update Existing File</div>
                    <Checkbox
                      className='file-selection-checkbox'
                      checked={updateExistingFileChecked}
                      onChange={onChangeExisting}
                      name='fileValue'
                    />
                  </div>
                  {createNewFileChecked && (
                    <div className='workshop-add-file-option'>
                      <div className='selection-label'>Standard Workshop Deck.pptx</div>
                      <button
                        className='template-preview-button'
                        onClick={() => handlePreviewClick(accessToken, idToken, projectId)}
                      >
                        Preview
                      </button>
                      <button className='template-download-button' onClick={handleTemplateDownload}>
                        <img src={download} className='download-icon' />
                        Download
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {updateExistingFileChecked && (
                <div>
                  <div className='upload-required-files'>Upload Required files</div>
                  <div className='upload-file-section'>
                    <div className='Upload-box'>
                      <Upload.Dragger
                        key={uploadKey}
                        className='upload-box-dragger'
                        {...Uploadprops}
                        accept='.pptx'
                        showUploadList={false}
                      >
                        <div className='drag-and-drop-text'>
                          Drag & Drop file(s) here or <span className='browse-button'>Browse</span>
                        </div>
                      </Upload.Dragger>
                    </div>
                    {updateFile.length > 0 || currentFilesUploading.length > 0 ? (
                      <div className='progress-bars-container'>
                        {currentFilesUploading &&
                          currentFilesUploading.map((fileObj: any, index: number) => (
                            <div key={index}>
                              <div className='progress-bar-section'>
                                {checkForMaximumFileSize(fileObj) &&
                                  checkMinimumFileSizeValidation(fileObj) &&
                                  fileObj?.percent === 100 && (
                                    <span className='checkIcon'>
                                      <img src={check} />
                                    </span>
                                  )}
                                {!checkForMaximumFileSize(fileObj) && fileObj?.percent === 100 && (
                                  <span className='checkIcon'>
                                    <WarningOutlined style={{ color: '#ff5a43' }} />
                                  </span>
                                )}
                                {!checkMinimumFileSizeValidation(fileObj) &&
                                  fileObj?.percent === 100 && (
                                    <span className='checkIcon'>
                                      <WarningOutlined style={{ color: '#ed8b00' }} />
                                    </span>
                                  )}
                                {fileObj?.name}
                                <span className='size-progress'>
                                  {`${(fileObj?.size / 1024 / 1024).toFixed(2)} MB`}
                                  <img src={divider} className='divider-icon' />
                                  <img
                                    src={cross}
                                    className='cross-icon'
                                    onClick={async () => {
                                      onRemoveFile(fileObj)
                                    }}
                                  />
                                </span>
                              </div>
                              <Progress
                                percent={fileObj?.percent}
                                strokeLinecap='butt'
                                size={['', 4]}
                                showInfo={false}
                                strokeColor={checkStrokeColor(fileObj)}
                              />
                              {checkForMaximumFileSize(fileObj) && fileObj?.percent === 100 && (
                                <div className='maximumWarning'>{generateWarning(fileObj)}</div>
                              )}
                              {checkMinimumFileSizeValidation(fileObj) &&
                                fileObj?.percent === 100 && (
                                  <div className='minimumWarning'>{generateWarning(fileObj)}</div>
                                )}
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className='knowledge-base-content-right'>
                        <div className='no-files-selected-text'>There are no Uploaded Files</div>
                        <div className='open-text'>
                          Please click Browse or use Drag & Drop area to upload files
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='upload-file-size-limit'>Maximum upload file size: 40MB</div>
                </div>
              )}
            </div>
          </div>
          <div className='output-filename-section-container'>
            <div className='output-filename-section-header'>Output File Name</div>
            <div className='output-filename-section-Box'>
              <div className='workshop-Output-file-box-header'>
                Please enter a file name to save the result in Sharepoint. Do not use special
                characters, and you do not need to enter a file extension. The file name you enter
                will automatically have a type and ID added to it.
              </div>
              <div className='workshop-name-box'>
                <Input
                  value={outputFileName}
                  onChange={(e) => {
                    const { value } = e.target
                    const filteredValue = value.replace(/[^a-zA-Z0-9 ]/g, '')
                    setOutputFileName(filteredValue)
                    setoutputFileNameData(filteredValue)
                  }}
                  placeholder='Type output file name'
                  className='workshop-name-input-field'
                />
              </div>
            </div>
          </div>
          <div className='button-section'>
            {checkCondition() && (
              <Tooltip
                title={
                  checkCondition()
                    ? 'Complete all required fields on this page to proceed further'
                    : ''
                }
              >
                <button
                  className={checkCondition() ? 'disabled' : 'next-button'}
                  onClick={() => {
                    if (!checkCondition()) {
                      setCurrentScreen('fileUpload')
                      setWorkshopNameData(workshopName)
                      setPromptData(promptValue)
                      setadditionalPromptData(additionalPrompt)
                      setoutputFileNameData(outputFileName)
                    }
                  }}
                >
                  Next
                </button>
              </Tooltip>
            )}
            {!checkCondition() && (
              <button
                className={checkCondition() ? 'disabled' : 'next-button'}
                onClick={() => {
                  if (!checkCondition()) {
                    setCurrentScreen('fileUpload')
                    setWorkshopNameData(workshopName)
                    setPromptData(promptValue)
                    setadditionalPromptData(additionalPrompt)
                    setoutputFileNameData(outputFileName)
                  }
                }}
              >
                Next
              </button>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default WorkshopDetails
