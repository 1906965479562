import { getUploadHeaders } from './headerconstants'

const API_URL = import.meta.env.VITE_KDD_API

const handleTemplateDownload = () => {
  const link = document.createElement('a')
  link.href = '/WorkshopAst_StandardTemplate.pptx'
  link.setAttribute('download', 'WorkshopAstStandardTemplate.pptx')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const handlePreviewClick = async (accessToken: string, idToken: string, projectId: string) => {
  const data = {
    filename: 'WorkshopAst_StandardTemplate.pptx',
    outputtype: 'Preview Link',
    project_id: projectId,
    template_type: 'standard',
  }
  const response = await fetch(`${API_URL}/WorkshopAst/preview_template/`, {
    method: 'POST',
    headers: getUploadHeaders(accessToken, idToken, projectId),
    body: JSON.stringify(data),
  })
  if (!response?.ok) {
    const message = `An error has occurred: ${response.statusText}`
    throw new Error(message)
  } else {
    const result = await response.json()
    console.log('result', result)
    window.open(result, '_blank')
  }
}

export { handleTemplateDownload, handlePreviewClick }
