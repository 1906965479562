import React from 'react'
import './index.scss'

interface IFileRowProps {
  extensions: string[]
  description: string
  imgSrc: string
}

const FileRow: React.FC<IFileRowProps> = ({ extensions, description, imgSrc }) => (
  <div className='optional-file-row'>
    {extensions.map((ext, index) => (
      <span key={index} className={`extention-type-${ext.replace('.', '')}`}>
        {ext}
      </span>
    ))}
    <span className='normal-text-tag'>{description}</span>
    <img src={imgSrc} alt='info' />
  </div>
)

export default FileRow
