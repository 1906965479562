import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Modal } from 'antd'
import './index.scss'

const TermsAndCondition: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div>
      <div className='my-3'>
        <span className='description'>
          <h3 className='popupHeader'>
            <strong>General - IPCO Licensing</strong>
          </h3>
          <p>
            Ascend was developed by Deloitte Consulting LLP (the &ldquo;<strong>US Firm</strong>
            &rdquo;). &nbsp;The US Firm has licensed Ascend to your member firm as &ldquo;Shared
            IP&rdquo; under the Shared Intellectual Property License Agreement between your member
            firm, Deloitte IPCO LLC, and certain other parties (the &ldquo;
            <strong>IPCO Agreement</strong>&rdquo;).
          </p>

          <p>
            Ascend is subject to the <strong>Category A</strong> Standard Use Restriction under the
            IPCO Agreement, which, in general, allows you and your firm to use Ascend for internal
            purposes only. &nbsp;Category A does{' '}
            <strong>
              <em>not</em>
            </strong>{' '}
            allow you or your firm to make any enhancements to Ascend, to allow non-Deloitte
            personnel to access it, or to license it to any client or other third party.
          </p>

          <p>
            Under the IPCO Agreement, Ascend is provided to your member firm &ldquo;as is,&rdquo;
            without warranty of any kind, and neither the US Firm nor any other Deloitte entity will
            have any liability or obligation to you or your member firm with respect to Ascend,
            including any obligation to support or maintain it. The above is just a summary, and for
            a more detailed explanation of IPCO and your member firm&rsquo;s license to use Ascend,
            please see the resources at the following link, including the IPCO Agreement embedded
            there:
            <a
              target='_blank'
              href='https://resources.deloitte.com/sites/kx/SitePages/IPCO-Standard-Use-Restrictions.aspx'
              rel='noreferrer'
            >
              https://resources.deloitte.com/sites/kx/SitePages/IPCO-Standard-Use-Restrictions.aspx
            </a>
            . &nbsp;Please direct any questions you may have about the IPCO Agreement to your member
            firm&rsquo;s legal group.
          </p>

          <h3 className='popupHeader'>
            <strong>Hosting and Support</strong>
          </h3>
          <p>
            Ascend is hosted and supported by the <strong>US Firm</strong>. All services are
            &ldquo;as is,&rdquo; without warranty of any kind, and neither the{' '}
            <strong>US Firm</strong> nor any of its related entities will have any liability or
            obligation related thereto.&nbsp;
          </p>

          <h3 className='popupHeader'>
            <strong>Compliance with Your Member Firm&rsquo;s Policies</strong>
          </h3>
          <p>When using Ascend, you must also comply with the following (as applicable):</p>
          <ul>
            <li>
              <span>
                Your member firm&rsquo;s rules and policies, including any rules or policies that
                your firm has adopted specifically for&nbsp;
              </span>
              Ascend
            </li>
            <li>
              <span>
                Your member firm&rsquo;s rules and policies and the treatment of confidential,
                proprietary, or personal information; and&nbsp;
              </span>
            </li>
            <li>
              <span>The terms of any applicable third party agreement.</span>
            </li>
          </ul>
          <p>
            In addition, your member firm may limit your access to only certain sections of Ascend,
            and if so, you may access and use only those sections and you are not allowed to access
            any other sections in Ascend.
          </p>

          <h3 className='popupHeader'>
            <strong>Revisions&nbsp;</strong>
          </h3>
          <p>
            The US Firm may change these Terms of Use from time to time, subject to any restrictions
            on its ability to do so under the IPCO Agreement. &nbsp;If it changes these Terms of
            Use, it will post the revised Terms of Use here or elsewhere in Ascend, and the revised
            Terms of Use will apply to you upon posting or such other date as specified in the
            revised Terms of Use. &nbsp;It is your responsibility to be aware of any such revised
            Terms of Use by checking this webpage. &nbsp;Your continued use of this Website
            following changes to these Terms of Use constitutes your agreement to the revised Terms
            of Use.
          </p>

          <h3 className='popupHeader'>
            <strong>Trademark</strong>
          </h3>
          <p>
            &ldquo;Deloitte&rdquo;, &ldquo;Touche&rdquo;, &ldquo;Tohmatsu&rdquo;, &ldquo;Deloitte
            Touche Tohmatsu&rdquo;, &ldquo;Deloitte &amp; Touche&rdquo;, the Deloitte logo, and
            local language variants of the foregoing trademarks, and certain product names and logos
            that appear in Ascend are trademarks or registered trademarks of entities within the
            Deloitte network. All other product names mentioned in Ascend are the trademarks of
            their respective owners and are mentioned for identification purposes only.
          </p>
        </span>
      </div>
    </div>
  )
}

export default TermsAndCondition
