import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import getToken from '../../addTokenInterceptor'
import './index.scss'

interface IBreadcrumb {
  currentScreen: string
  setCurrentScreen: (value: string) => void
}
const BreadCrumb: React.FC<IBreadcrumb> = (props: IBreadcrumb) => {
  const location = useLocation()
  const { projectId } = getToken()
  const navigate = useNavigate()
  return (
    <div className='breadcrumb-container'>
      <div className='breadcrumb-non-active-head'>Workshop Assistant </div>
      <div className='divider'> {`>`} </div>
      <div
        className={
          props?.currentScreen === 'WorkshopDetails'
            ? 'breadcrumb-active-head'
            : 'breadcrumb-non-active-head'
        }
        onClick={() => {
          props?.setCurrentScreen('WorkshopDetails')
        }}
      >
        Workshop Details
      </div>
      {props?.currentScreen === 'fileUpload' && (
        <>
          <div className='divider'> {`>`} </div>
          <div
            className={
              props?.currentScreen === 'fileUpload'
                ? 'breadcrumb-active-head'
                : 'breadcrumb-non-active-head'
            }
          >
            Files Upload & Output Generation
          </div>
        </>
      )}
    </div>
  )
}

export default BreadCrumb
