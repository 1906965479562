import { Mermaid } from './Mermaid'
import { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../DataContext'
import './index.scss'

const BlockDiagram: React.FC = () => {
  const [mermaidProps, setMermaidProps] = useState({ text: '' })
  const { createSnippetValue } = useContext(DataContext)

  useEffect(() => {
    const idNameMapping: { [key: number]: string } = {}
    createSnippetValue.forEach(
      ({ name, id }: { name: string; id: number; RecommendedTopic: string }) => {
        idNameMapping[id] = name
      },
    )
    let blockDiagramText = `graph TD\n`
    createSnippetValue.forEach(
      ({ id, parentId }: { parentId: number; id: number; RecommendedTopic: string }) => {
        if (parentId != 0)
          blockDiagramText += `id${parentId}["${idNameMapping[parentId]}"] --> id${id}["${idNameMapping[id]}"]\n`
        else blockDiagramText += `id${id}["${idNameMapping[id]}"]\n`
      },
    )
    setMermaidProps({
      text: blockDiagramText,
    })
  }, [])
  return <>{mermaidProps.text !== '' && <Mermaid {...mermaidProps} />}</>
}

export default BlockDiagram
