const cspValues = {
  'default-src': "'self'",
  'style-src': "'self' 'unsafe-inline' https://fonts.googleapis.com https://cdn.cookielaw.org",
  'script-src': "'self' 'unsafe-inline'",
  'font-src': "'self' https://fonts.googleapis.com",
  'connect-src':
    "'self' https://login.microsoftonline.com https://*.blob.core.windows.net https://*.deloitte.com",
}

const cspHeader = Object.entries(cspValues)
  .map(([key, value]) => `${key} ${value}`)
  .join('; ')

const getHeaders = (accessToken: string, idToken: string, projectId: string) => {
  const headerConstants = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${idToken}`,
    'Access-Token': accessToken,
    'project-id': projectId,
    'Content-Security-Policy': cspHeader,
    'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate',
    Pragma: 'no-cache',
  }
  return headerConstants
}

const getUploadHeaders = (accessToken: string, idToken: string, projectId: string) => {
  const headerConstants = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${idToken}`,
    'Access-Token': accessToken,
    'project-id': projectId,
    'Accept-Encoding': 'gzip, deflate, br',
    'Content-Security-Policy': cspHeader,
    'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate',
    Pragma: 'no-cache',
  }
  return headerConstants
}

const getDefaultHeaders = (accessToken: string, idToken: string, projectId: string) => {
  const headerConstants = {
    Authorization: `Bearer ${idToken}`,
    'Access-Token': accessToken,
    'project-id': projectId,
    'Content-Security-Policy': cspHeader,
    'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate',
    Pragma: 'no-cache',
  }
  return headerConstants
}

export { getHeaders, getUploadHeaders, getDefaultHeaders }
