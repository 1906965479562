import React from 'react'
import FileRow from './FileRowComponent'
import info from '../../assets/Description.svg' // Adjust path as needed
import './index.scss'

const fileRows = [
  {
    extensions: ['.vtt', '.txt', '.ics'],
    description: 'Audio Transcript + Meeting Invite',
    imgSrc: info,
  },
  {
    extensions: ['.m4a', '.mp4', '.ics'],
    description: 'Meeting Recording + Meeting Invite',
    imgSrc: info,
  },
  {
    extensions: ['.xls'],
    description: 'RAID Log',
    imgSrc: info,
  },
  {
    extensions: ['.ppt'],
    description: 'Deep Dive Decks',
    imgSrc: info,
  },
  {
    extensions: ['.msg', '.eml'],
    description: 'Emails',
    imgSrc: info,
  },
  {
    extensions: ['.png', '.jpeg'],
    description: 'Screenshots/Images',
    imgSrc: info,
  },
  {
    extensions: ['.docx', '.pdf', '.pptx', '.xlsx'],
    description: 'Related Documentation',
    imgSrc: info,
  },
]

const OptionalFileList: React.FC = () => (
  <>
    <div className='upload-multiple-file-content-left-heading'>Optional files:</div>
    {fileRows.map((row, index) => (
      <FileRow
        key={index}
        extensions={row.extensions}
        description={row.description}
        imgSrc={row.imgSrc}
      />
    ))}
  </>
)

export default OptionalFileList
