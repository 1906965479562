import { API_GET_PROJECTNAME, API_GET_OUTPUTID } from '../urls'
import { getHeaders } from './headerconstants'

const getProjectNames = async (accessToken: string, idToken: string, projectId: string) => {
  const data = {
    projectId: String(projectId),
  }
  const response = await fetch(API_GET_PROJECTNAME, {
    method: 'POST',
    headers: getHeaders(accessToken, idToken, projectId),
    body: JSON.stringify(data),
  })
  if (!response?.ok) {
    const message = `An error has occurred: ${response.statusText}`
    throw new Error(message)
  } else {
    const result = await response.json()
    return result.result.data
  }
}

const getOutputId = async (projectId: number, idToken: string) => {
  const data = {
    projectId: Number(projectId),
  }
  const cspValues = {
    'default-src': "'self'",
    'style-src': "'self' 'unsafe-inline' https://fonts.googleapis.com https://cdn.cookielaw.org",
    'script-src': "'self' 'unsafe-inline'",
    'font-src': "'self' https://fonts.googleapis.com",
    'connect-src':
      "'self' https://login.microsoftonline.com https://*.blob.core.windows.net https://*.deloitte.com",
  }

  const cspHeader = Object.entries(cspValues)
    .map(([key, value]) => `${key} ${value}`)
    .join('; ')
  const response = await fetch(API_GET_OUTPUTID, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
      'Content-Security-Policy': cspHeader,
      'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate',
      Pragma: 'no-cache',
    },
    body: JSON.stringify(data),
  })
  if (!response?.ok) {
    const message = `An error has occurred: ${response.statusText}`
    throw new Error(message)
  } else {
    const result = await response.json()
    const outputId = result.result.data
    return outputId
  }
}

export { getProjectNames, getOutputId }
