import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Modal } from 'antd'
import './index.scss'
import TermsAndCondition from '../TermsAndConditions'
import { Link, useNavigate } from 'react-router-dom'
import getToken from '../../addTokenInterceptor'

const Footer: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { projectId } = getToken()
  const navigate = useNavigate()
  const showModal = () => {
    setIsModalOpen(true)
  }
  const ToggleInfoDisplay = () => {
    ;(window as any)?.OneTrust?.ToggleInfoDisplay()
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const setUrl = () => {
    console.log(window.location.href)
    return 'https://127.0.0.1:4200/notices/?ascend_project_id=3903'
  }

  return (
    <div className='copy-right'>
      Copyright © 2024 Deloitte Development LLC. All rights reserved. See
      <a
        href='https://www2.deloitte.com/us/en/footerlinks1/privacy-notice-limited-controller.html'
        target='_blank'
        className='notice-link'
        rel='noreferrer'
      >
        Privacy Policy
      </a>
      ,
      <a className='notice-link' onClick={showModal}>
        Terms of use
      </a>
      <Modal
        style={{ width: '70%' }}
        title='Terms of use'
        open={isModalOpen}
        closable={true}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TermsAndCondition />
      </Modal>,
      <a
        className='notice-link'
        href='https://cookienotice.deloitte.com'
        target='_blank'
        rel='noreferrer'
      >
        Cookies
      </a>
      ,
      <a className='notice-link' onClick={ToggleInfoDisplay}>Cookie Settings
      </a>
      and
      <a
        onClick={() => {
          navigate(`/notices/?ascend_project_id=${projectId}`)
        }}
        target='_blank'
        className='notice-link'
      >
      Notices
      </a>
      for more information.
    </div>
  )
}

export default Footer
