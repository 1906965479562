import React from 'react'
import KnowledgeBaseImport from '../../Components/KnowledgeBaseComponent'
import check from '../../assets/checkIcon.svg'
import cross from '../../assets/cross.svg'
import divider from '../../assets/divider.svg'
import { Progress } from 'antd'
import './index.scss'

interface IProps {
  kbFiles: string[]
  setKbFiles: (files: any) => void
  myProps: any
}

const KnbFileUploadComponent: React.FC<IProps> = ({ kbFiles, setKbFiles, myProps }) => (
  <div className='knowledge-base-container'>
    <div className='knowledge-base-header'>Select files from Deloitte Knowledge Base</div>
    <div className='knowledge-base-content'>
      <div className='knowledge-base-content-left'>
        <div className='knowledge-base-content-left-heading'>
          You can select documents from Knowledge Base as references for generating the output.
        </div>
        <div className='kb-button'>
          <KnowledgeBaseImport {...myProps} />
        </div>
      </div>
      {kbFiles.length === 0 ? (
        <div className='knowledge-base-content-right'>
          <div className='no-files-selected-text'>There are no Selected Files</div>
          <div className='open-text'>Please click Open Knowledge Base to select files.</div>
        </div>
      ) : (
        <div className='progress-bars-container'>
          {kbFiles.map((fileObj, index) => (
            <div key={index}>
              <div className='progress-bar-section'>
                <span className='checkIcon'>
                  <img src={check} />
                </span>
                {fileObj}
                <span className='size-progress'>
                  <img src={divider} className='divider-icon' />
                  <img
                    src={cross}
                    className='cross-icon'
                    onClick={() => {
                      const newItems = kbFiles.filter((_, i) => i !== index)
                      myProps.addfilescallback(newItems)
                    }}
                  />
                </span>
              </div>
              <Progress
                percent={100}
                showInfo={false}
                strokeLinecap='butt'
                size={[, 4]}
                strokeColor={'#007CB0'}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
)

export default KnbFileUploadComponent
