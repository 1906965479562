import React, { useEffect } from 'react'
import mermaid from 'mermaid'

export interface IMermaidProps {
  text: string
}

export const Mermaid: React.FC<IMermaidProps> = ({ text }) => {
  const ref = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    mermaid.mermaidAPI.initialize({
      startOnLoad: true,
      securityLevel: 'loose',
      theme: 'null',
      logLevel: 5,
    })
  })

  useEffect(() => {
    if (ref && ref.current && text !== '')
      mermaid.render('preview', text, (result) => {
        if (ref && ref.current && text !== '') ref.current.innerHTML = result;
      }, ref.current); // add ref.current as 4th parameter
  }, [text, ref])

  return <div key='preview' ref={ref} />
}
