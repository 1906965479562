export const emptyStateText =
  'Type your request or question at the bottom of the screen or use Recommended Topics on the left hand side if you need help with creating request.'
export const regerateText =
  "Please regenerate the response similar to the previous one. If the previous response included tables, ensure to include the same in the updated version, catering to the scenario. If the previous table had fields like 'Agenda Item' and 'Time', your response should also include these, with the agenda items reflecting our current tasks and the timings being suitable for all team members. Remember, the goal is to maintain the structure while refreshing the content to align with our current context"
export const regenerateWithoutContext = 'Regenerate previous response'
export const elaborateText = `As an advanced AI specializing in generating detailed and elaborated responses, your task is to provide additional insights and context to the previous chatbot answer without repeating the previous response. The elaboration should be in the same format as the previous result, enhancing the information wherever applicable.

Instructions:
1. Take the previous chatbot answer as input.
2. Identify key points where additional information, context, or insights would be beneficial and elaborate only on those points.
3. Do not repeat the content of the previous response.
4. Maintain the original formatting and structure of the previous answer.
5. Ensure the elaborated response is coherent and follows a logical flow.

Elaborated response:
`
export const summarizeText = `As an advanced AI specializing in generating concise and summarized responses, your task is to provide a brief summary of the previous chatbot answer without repeating the original response. The summary should maintain the same format as the previous result, focusing on the key points and essential information.

Instructions:
1. Take the previous chatbot answer as input.
2. Identify the key points and essential information.
3. Summarize these key points concisely without repeating the content of the previous response.
4. Provide only the summary in your response, omitting the original content.
5. Preserve the original formatting and structure of the previous answer.
6. Ensure the summarized response is coherent and follows a logical flow.

Summary:`
