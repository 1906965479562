import React, { useContext } from 'react'
import './index.scss'
import { Input, Select, Spin } from 'antd'
import { RecommendedTopicText } from './constants'
import { LoadingOutlined } from '@ant-design/icons'
import Footer from '../Footer'
import { DataContext } from '../../DataContext'

interface ICreateRequestFormProps {
  handleChange: (value: string, key: string) => void
  handleDropdownChange: (value: string) => void
  industryTrends: string
  configurationsteps: string
  agenda: string
  promptValue: string
  roleName: string | null
  generatePrompt: () => void
  requestLoader: boolean
  requestValue: string
}

const { Option } = Select

const CreateRequestForm: React.FC<ICreateRequestFormProps> = (props: ICreateRequestFormProps) => {
  const { sections } = useContext(DataContext)
  const topicListStr = JSON.stringify(sections)
  const topicListArr = topicListStr ? JSON.parse(topicListStr) : []
  const newArr: { label: string; value: string }[] = []
  if (topicListStr) {
    topicListArr.map((obj: string) => {
      const newObject = {
        label: obj,
        value: obj,
      }
      newArr.push(newObject)
    })
  }
  const topicList = newArr
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  return (
    <div className='create-request-section'>
      <div className='create-request-top-section'>
        {/* <div className='prepareWorkhopText'>
          <img className='infoIconWorkshop' src={info} />
          {prepareWorkshopText}
        </div> */}
        {/* <div className='workshop-name-section'>
          <label className='labelText'>Workshop Section</label>
          <Input
            className='inputField'
            value={props.roleName}
            placeholder='Enter Workshop Name'
            onChange={(e) => props.handleChange(e.target.value, 'roleName')}
          />
        </div> */}

        <div className='field-section'>
          <div className='recommendedTopicHeading'>Workshop Section</div>
          <div className='recommendedTopicContent'>{RecommendedTopicText}</div>
        </div>
        <div className='field-section'>
          <label className='labelText'>Section</label>
          <Select
            style={{ width: '100%' }}
            value={props.promptValue}
            placeholder='Select Topic'
            onChange={(e) => props.handleDropdownChange(e)}
          >
            {topicList.map((topic: { label: string; value: string }, index: number) => (
              <Option key={index} value={topic.value}>
                {topic.label}
              </Option>
            ))}
          </Select>
        </div>

        {props.promptValue && (
          <div className='field-section'>
            <div className='inputFieldSection'>
              <label className='labelText'>Request</label>
            </div>
            <Input
              className='inputField'
              value={props.requestValue}
              onChange={(e) => props.handleChange(e.target.value, 'requestValue')}
            />
          </div>
        )}
        {props.promptValue && (
          <div className='create-request-bottom-section'>
            <button
              className='createRequestButton'
              style={!props.requestValue ? { opacity: '60%' } : { opacity: '1', cursor: 'pointer' }}
              onClick={() => {
                if (props.requestValue) props.generatePrompt()
              }}
            >
              {props?.requestLoader && <Spin indicator={antIcon} className='loader-spin' />}
              Create Request
            </button>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default CreateRequestForm
