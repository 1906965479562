import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import WorkshopDetails from '../Workshop-details'
import FileUpload from '../FileUpload'
import { DataContext, DataProvider } from '../../DataContext'
import Prepare from '../Prepare'

const KDDLanding: React.FC = () => {
  const location = useLocation()
  // const [existingFileList, setExistingFileList] = useState([])
  // const [screen, setCurrentScreen] = useState('WorkshopDetails')
  const { currentScreen } = useContext(DataContext)
  // const FileUploadProps = {
  //   existingFilesListAdded: existingFileList,
  // }
  // useEffect(() => {
  //   // if (JSON.parse(JSON.stringify(localStorage.getItem('currentScreen'))))
  //   //   setCurrentScreen(JSON.parse(JSON.stringify(localStorage.getItem('currentScreen'))))
  //   // console.log('existingFileList', existingFileList)
  // }, [existingFileList])
  return (
    <div>
      {currentScreen === 'WorkshopDetails' && <WorkshopDetails />}
      {currentScreen === 'fileUpload' && <FileUpload />}
      {currentScreen === 'prepare' && <Prepare />}
    </div>
  )
}

export default KDDLanding
