import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { RouterProvider, createHashRouter, createBrowserRouter } from 'react-router-dom'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './AuthConfig'
import Prepare from './Screens/Prepare'
import WorkshopDetails from './Screens/Workshop-details'
import Notice from './Components/NoticesComponent/index'
import FileUpload from './Screens/FileUpload'

const msalInstance = new PublicClientApplication(msalConfig)
interface ISetCookieResponse {
  message: string
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    // errorElement: <Navigate to='/preparation' replace />,
  },
  {
    path: '/prepare',
    element: <Prepare />,
  },
  {
    path: '/notices',
    element: <Notice />,
  },
  {
    path: '/file-upload',
    element: <FileUpload />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <MsalProvider instance={msalInstance}>
    <RouterProvider router={router} />
  </MsalProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

async function setCookie(
  name: string,
  value: string,
  path: string,
  expires: number,
  domain?: string,
): Promise<ISetCookieResponse> {
  try {
    await (window as any)?.cookieStore?.set({
      name,
      value,
      domain,
      path,
      expires,
    })

    return { message: 'Success setting cookie' }
  } catch (error) {
    return { message: 'Error setting cookie' }
  }
}

// function to get the cookie value
function getCookie(cname: string) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}

const cookieScript = document.createElement('script')
cookieScript.type = 'text/javascript'
cookieScript.async = true
cookieScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
cookieScript.setAttribute('data-domain-script', 'a2d1e776-f25c-40db-8440-f32a1c244d13-test')
console.log('Executed')
document.body.appendChild(cookieScript)

const launchScript = document.createElement('script')
launchScript.type = 'text/javascript'
// if (JSON.parse(environment.production))
launchScript.src = '//assets.adobedtm.com/571f1a0c43d6/b821caed2815/launch-0eebf70e1ab1.min.js'
// else launchScript.src = "//assets.adobedtm.com/571f1a0c43d6/b821caed2815/launch-0ac577463a9c-development.min.js";
document.getElementsByTagName('head')[0].appendChild(launchScript)

// adding action listener for custom events from OneTrust script
window.addEventListener('OneTrustGroupsUpdated', async (event) => {
  const optOnConsentSharingValue = getCookie('OptOnConsentSharing_GenAi')
  const isAlertBox = getCookie('AlertBox')

  if (!isAlertBox && optOnConsentSharingValue) {
    const crossDomainValues = optOnConsentSharingValue.split(' | ')

    await setCookie(
      'OptanonAlertBoxClosed',
      crossDomainValues[0],
      '/',
      Number(crossDomainValues[1]),
    )
    await setCookie('OptanonConsent', crossDomainValues[2], '/', Number(crossDomainValues[3]))
  }

  // setting cross domain cookie on close of alertbox
  else if ((window as any)?.OneTrust?.IsAlertBoxClosed()) {
    const alertBoxClosedCookie = await (window as any)?.cookieStore?.get('OptanonAlertBoxClosed')
    const optanonConsentCookie = await (window as any)?.cookieStore?.get('OptanonConsent')

    const crossDomainValue =
      alertBoxClosedCookie.value +
      ' | ' +
      alertBoxClosedCookie.expires +
      ' | ' +
      optanonConsentCookie.value +
      ' | ' +
      optanonConsentCookie.expires

    const day = 30 * 24 * 60 * 60 * 1000
    const oneMonthFromNow = Date.now() + day

    await setCookie(
      'OptOnConsentSharing_GenAi',
      crossDomainValue,

      '/',
      oneMonthFromNow,
      'deloitte.com',
    )
    await setCookie('AlertBox', 'True', '/', oneMonthFromNow)
  } else {
    // console.log("Condition");
  }
})
