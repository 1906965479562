import { useContext } from 'react'
import './index.scss'
import checked from '../../assets/checked.svg'
import popupImage from '../../assets/output-page-video.mp4'
import downloadIcon from '../../assets/downloadImg.svg'
import axios from 'axios'
import { DataContext } from '../../DataContext'

const GenerateOutputModal: React.FC = () => {
  const { commonOutputID } = useContext(DataContext)
  const downloadPlugin = async () => {
    const url = import.meta.env.VITE_DOWNLOAD_PLUGIN_URL

    axios
      .get(url, { responseType: 'text' })
      .then((response) => {
        const { data } = response
        const blob = new Blob([data], { type: 'text/xml' })

        // Create a temporary anchor element and set its attributes
        const anchor = document.createElement('a')
        anchor.href = window.URL.createObjectURL(blob)
        const envUsed = import.meta.env.VITE_ENV
        anchor.download = `SmartAssist.xml_${envUsed}`

        // Append anchor to the body to make it work in Firefox
        document.body.appendChild(anchor)

        // Trigger a click event to simulate the download
        anchor.click()

        // Clean up resources
        setTimeout(() => {
          // Delay needed to revoke the URL after the download starts
          window.URL.revokeObjectURL(anchor.href)
          anchor.remove()
        }, 100) // Adjust timeout as needed
      })
      .catch((error) => {
        console.error('Error downloading the file:', error)
      })
  }
  return (
    <div className='generateOutputModal'>
      <div className='generateOutputModalLeft'>
        <div className='generateOutputModalLeftHeader'>
          <img src={checked} />
          <div className='generateOutputModalHeaderSection'>
            <div className='results-title'>Results are being generated</div>
            <div className='results-subTitle'>
              The results will show up on the output screen shortly. Your output ID:{' '}
              {commonOutputID}
            </div>
          </div>
        </div>
        <div className='generateOutputHeaderContent'>
          <div className='what-is-next'>What is next?</div>
          <div className='pointers'>
            <div className='point-number-1'>1</div>
            <div className='point-content'>
              <div className='point-header'>Open the file</div>
              <div className='point-value'>
                When it will be ready, launch the User Stories result file from the Outputs screen.
                It will be opened in MS Word.
              </div>
            </div>
          </div>
          <div className='pointers'>
            <div className='point-number-2'>2</div>
            <div className='point-content'>
              <div className='point-header'>Make edits if needed</div>
              <div className='point-value'>
                If you want to refine Deep Dive Deck document and you doing it first time, you
                should enable the plugin. Click ‘Download the plugin’, run the downloaded file, and
                follow further instructions. If you done it once, you don’t need to repeat.
              </div>
              <button className='download-plugin' onClick={downloadPlugin}>
                <img src={downloadIcon} />
                Download Plugin
              </button>
            </div>
          </div>
          <div className='pointers'>
            <div className='point-number-3'>3</div>
            <div className='point-content'>
              <div className='point-header'>Save and share the document with your team</div>
              <div className='point-value'>
                Once you are finished with the document refinement, save it in MS Word. After saving
                the document will be available to your project team members in MS Word and MS Teams.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='generateOutputModalRight'>
        <video
          width='400'
          height='500'
          autoPlay
          loop
          muted
          playsInline
          className='popupImage'
          style={{ width: '100%' }}
        >
          <source src={popupImage} type='video/mp4' />
        </video>
      </div>
    </div>
  )
}

export default GenerateOutputModal
